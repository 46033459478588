import { useEffect, useState } from "react";
import { Button, Alert, Box, CircularProgress } from "@mui/material";
import { useAuthContext } from "src/auth/hooks";
import { useFormsContext } from "src/components/forms";
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export const DetectScript = () => {
    const router = useRouter();
    const { user } = useAuthContext();
    const { detectScript, snippetFound } = useFormsContext();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(user?.account_id.id) {   
            const detect = async () => {    
                await detectScript(user?.account_id.id);
            }
            detect();
        }
    }, [user, detectScript]);

    const verifyCode = async () => {
        setLoading(true);
        const res = await detectScript(user?.account_id.id);
        if(res) {
            router.push(paths.dashboard.forms.root);
        }
        setLoading(false);
    }

  if(snippetFound) {
    return null;
  }
    
  return (
    <Alert
      severity="warning"
      sx={{
        position: 'fixed',
        top: 10,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: 600,
        display: 'flex',
        alignItems: 'center',
        gap: 1,

        '& .MuiAlert-action': {
        padding: '0px',
        },
    }}
    action={
        <Button size="small" variant="contained" onClick={verifyCode} disabled={loading}>
          {loading && <CircularProgress size={20} />} Verify Code
        </Button>
    }
    >
        <Box>The provided code snippet was not detected on your website.</Box>
        </Alert>
    );
};

// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// theme
import { bgBlur } from 'src/theme/css';
import Iconify from 'src/components/iconify';
// utils
import { fDate } from 'src/utils/format-time';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import { usePathname, useRouter } from 'src/routes/hooks';
import { usePopover } from 'src/components/custom-popover';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import { useFormsContext } from 'src/components/forms';
import CustomDateRangePicker, {
  useDateRangePicker,
} from 'src/sections/form/form-date-range-picker';
import ic_menu_item from 'src/assets/icons/navbar/ic_menu_item.svg';
//
import { HEADER, NAV } from '../config-layout';
import { AccountPopover } from '../_common';
import { DetectScript } from './detect-script';
// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const pathname = usePathname();
  const router = useRouter();
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  const rangePicker = useDateRangePicker(lastWeek, today);

  const { changeDate, startDate, endDate } = useFormsContext();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const pageTitle: { [key: string]: string } = {
    '/dashboard': 'Dashboard',
    '/dashboard/forms': 'Forms',
    '/dashboard/campaigns': 'Campaigns',
    '/dashboard/user/personal-profile': 'Your Profile',
    '/dashboard/user/company-account': 'Company Account',
  };

  const isDashboardPage = pathname === '/dashboard';

  const getPageTitle = () => {
    const basePath = Object.keys(pageTitle).find((key) =>
      pathname.startsWith(key),
    );

    if (basePath && pathname !== basePath) {
      return (
        <Button
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          onClick={() => router.back()}
        >
          Back
        </Button>
      );
    }

    return basePath ? pageTitle[basePath] : '';
  };

  const isFormsPage = pathname.startsWith('/dashboard/forms');



  const popover = usePopover();

  const onApply = () => {
    if (rangePicker.startDate && rangePicker.endDate && startDate !== rangePicker.startDate && endDate !== rangePicker.endDate) {
      changeDate(rangePicker.startDate, rangePicker.endDate);
    }
    popover.onClose();
  };

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src={ic_menu_item} />
        </IconButton>
      )}

      <Typography variant="h4">
        {pageTitle?.[pathname] || getPageTitle()}
      </Typography>

      <DetectScript />
      {/* <Searchbar /> */}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        {isFormsPage && (
          <Box>
            <Button onClick={popover.onOpen}>
              <Stack
                sx={{
                  p: '4px 6px',
                  typography: 'body2',
                  border: '1px solid rgba(145, 158, 171, 0.20)',
                  borderRadius: '6px',
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                }}
                flexDirection="row"
                alignItems="center"
              >
                {fDate(rangePicker.startDate || lastWeek, 'yyyy-MM-dd')} to{' '}
                {fDate(rangePicker.endDate || today, 'yyyy-MM-dd')}
                <Iconify
                  icon="fluent:calendar-24-regular"
                  width={16}
                  sx={{ ml: 1 }}
                />
              </Stack>
            </Button>
            <CustomDateRangePicker
              variant="calendar"
              title="Choose due date"
              startDate={rangePicker.startDate}
              endDate={rangePicker.endDate}
              onChangeStartDate={rangePicker.onChangeStartDate}
              onChangeEndDate={rangePicker.onChangeEndDate}
              open={popover.open}
              onClose={popover.onClose}
              selected={rangePicker.selected}
              error={rangePicker.error}
              onApply={onApply}
            />
          </Box>
        )}
        {/* <SettingsButton /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(theme.palette.mode === 'light' && {
          background: '#F9FAFB',
        }),

        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          ...(!isDashboardPage && {
            maxWidth: '1200px',
            margin: '0 auto',
            width: '100%',
          }),
          px: '24px',
          [theme.breakpoints.down(1500)]: {
            px: '40px',
          },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

import { format } from 'date-fns';

import { useMemo, useCallback, useReducer } from 'react';
import axios from 'axios';

import { HOST_API } from 'src/config-global';
import { FormsContext } from './forms-context';

// ----------------------------------------------------------------------

enum Types {
  FORM_CONTACTS = 'FORM_CONTACTS',
  LOADER = 'LOADER',
  FORM_LIST = 'FORM_LIST',
  FORM_DETAILS = 'FORM_DETAILS',
  FORM_INACTIVE = 'FORM_INACTIVE',
  FORM_STATISTICS = 'FORM_STATISTICS',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  SNIPPET_FOUND = 'SNIPPET_FOUND',
}

type FormsProviderProps = {
  children: React.ReactNode;
};

const initialState: any = {
  formContacts: [],
  loading: true,
  formList: null,
  formDetails: null,
  formInactive: [],
  formStatistics: [],
  startDate: null,
  endDate: null,
  snippetFound: true,
};

const reducer = (state: any, action: any) => {
  if (action.type === Types.FORM_CONTACTS) {
    return {
      ...state,
      loading: false,
      formContacts: action.payload.formContacts,
    };
  }

  if (action.type === Types.START_DATE) {
    return {
      ...state,
      startDate: action.payload.startDate,
    };
  }

  if (action.type === Types.END_DATE) {
    return {
      ...state,
      endDate: action.payload.endDate,
    };
  }
  
  if(action.type === Types.FORM_INACTIVE) {
    return {
      ...state,
      formInactive: action.payload.formInactive,
    };
  }

  if (action.type === Types.FORM_STATISTICS) {
    return {
      ...state,
      formStatistics: action.payload.formStatistics,
    };
  }

  if (action.type === Types.FORM_LIST) {
    return {
      ...state,
      formList: action.payload.formList,
    };
  }

  if (action.type === Types.FORM_DETAILS) {
    return {
      ...state,
      formDetails: action.payload.formDetails,
    };
  }
  if (action.type === Types.LOADER) {
    return {
      loading: false,
    };
  }

  if (action.type === Types.SNIPPET_FOUND) {
    return {
      ...state,
      snippetFound: action.payload,
    };
  }

  return state;
};

export function FormsProvider({ children }: FormsProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeDate = useCallback((startDate: any, endDate: any) => {
    dispatch({
      type: Types.START_DATE,
      payload: {
        startDate,
      },
    });
    dispatch({
      type: Types.END_DATE,
      payload: {
        endDate,
      },
    });
  }, []);

  // GET FORM CONTACTS
  const getFormContacts = useCallback(
    async ({ formId, startDate, endDate }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const res = await axios.get(
          `${HOST_API}/form/${formId}/contacts/?cid=zxcvb${
            startDate && endDate
              ? `&fromDate=${format(
                  new Date(startDate),
                  'dd-MM-yyyy',
                )}&toDate=${format(new Date(endDate), 'dd-MM-yyyy')}`
              : ''
          }`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        dispatch({
          type: Types.FORM_CONTACTS,
          payload: {
            formContacts: data,
          },
        });
      } catch (e) {
        dispatch({
          type: Types.LOADER,
        });
        console.error(e);
      }
    },
    [],
  );

  // GET FORM LIST
  const getFormList = useCallback(
    async ({ startDate, endDate, sort, s, followers, isActive }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const cid = localStorage.getItem('cid');
        const res = await axios.get(
          `${HOST_API}/form/list/?${
            isActive === null ? '' : `isActive=${isActive}`
          }&cid=${cid}${
            startDate && endDate
              ? `&fromDate=${format(
                  new Date(startDate),
                  'dd-MM-yyyy',
                )}&toDate=${format(new Date(endDate), 'dd-MM-yyyy')}`
              : ''
          }${s ? `&s=${s}` : ''}${sort ? `&sort=${sort}` : ''}${
            followers ? `&followers=${followers}` : ''
          }`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        if (!isActive && isActive !== null) {
          dispatch({
            type: Types.FORM_INACTIVE,
            payload: {
              formInactive: data,
            },
          });
        } else {
          dispatch({
            type: Types.FORM_LIST,
            payload: {
              formList: data,
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  // GET FORM DETAILS
  const getFormDetails = useCallback(
    async ({ formId, startDate, endDate }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const res = await axios.get(
          `${HOST_API}/form/${formId}/?cid=zxcvb${
            startDate && endDate
              ? `&fromDate=${format(
                  new Date(startDate),
                  'dd-MM-yyyy',
                )}&toDate=${format(new Date(endDate), 'dd-MM-yyyy')}`
              : ''
          }`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        dispatch({
          type: Types.FORM_DETAILS,
          payload: {
            formDetails: data,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  // GET FORM STATISTICS
  const getFormStatistics = useCallback(
    async ({ formId, startDate, endDate }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const res = await axios.get(
          `${HOST_API}/form/${formId}/statistics?cid=zxcvb${
            startDate && endDate
              ? `&fromDate=${format(
                  new Date(startDate),
                  'dd-MM-yyyy',
                )}&toDate=${format(new Date(endDate), 'dd-MM-yyyy')}`
              : ''
          }`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        dispatch({
          type: Types.FORM_STATISTICS,
          payload: {
            formStatistics: data.data,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const clearFormDetails = useCallback(async () => {
    dispatch({
      type: Types.FORM_DETAILS,
      payload: {
        formDetails: null,
      },
    });
  }, []);

  // EDIT FORM DETAILS
  const editFormDetails = useCallback(async ({ formId, data }: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      console.log('Sending data to server:', JSON.stringify({ data }, null, 2));
      const response = await axios.put(
        `${HOST_API}/form/${formId}/?cid=zxcvb`,
        { data },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      console.log('Server response:', response.data);
    } catch (error) {
      console.error(
        'Error in editFormDetails:',
        error.response ? error.response.data : error.message,
      );
    }
  }, []);

  // EDIT FORM ACTIVE
  const editFormActive = useCallback(
    async ({ formId, isActive }: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        await axios.put(
          `${HOST_API}/form/activate/${formId}`,
          {
            isActive,
          },
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          },
        );
        await getFormList({ isActive: !isActive ? null : false });
      } catch (error) {
        console.error(
          'Error in editFormDetails:',
          error.response ? error.response.data : error.message,
        );
      }
    },
    [getFormList],
  );

  // GET FORM INACTIVE
  const getFormInactive = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await axios.get(
        `${HOST_API}/form/inactive?cid=zxcvb`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const { data } = res;

      dispatch({
        type: Types.FORM_INACTIVE,
        payload: {
          inactiveForm: data,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  // DETECT SCRIPT
  const detectScript = useCallback(
    async (accountId: any) => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const res = await axios.get(
          `${HOST_API}/detect-script?accountId=${accountId}`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        console.log(res);
        dispatch({
          type: Types.SNIPPET_FOUND,
          payload: data.success,
        });
        return data.success;
      } catch (e) {
        return e.response.data;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // NEW CRAWL
  const newCrawl = useCallback(
    async (url: any) => {
      console.log('url', url);
      try {
        const accessToken = localStorage.getItem('accessToken');
        const cid = localStorage.getItem('cid');
        const res = await axios.get(
          // `https://crm-api.produktiv.agency/api/crawl-website/?cid=zxcvb&url=${url}`,
          `${HOST_API}/crawl-website?cid=${cid}&url=${url}&inputsToExclude=post_id,id,referer_title,queried_id,s,form_id&searchFormNames=s,search,query&mode=aggressive`,
          {
            headers: {
              'Powered-By': 'Produktiv',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const { data } = res;

        await new Promise((resolve) => setTimeout(resolve, 4000));
        await checkStatusCrawl(data?.executionId);
        // latestCrawl(url);
        return data;
      } catch (e) {
        return e.response.data;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // CHECK STATUS
  const checkStatusCrawl = useCallback(async (executionId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken'); 
      const cid = localStorage.getItem('cid');
      await axios.get(
        `${HOST_API}/crawl-website/${executionId}?cid=${cid}`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // LATEST CRAWL
  const latestCrawl = useCallback(async (url: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.get(
        `https://crm-api.produktiv.agency/api/crawl-website/latest?cid=zxcvb&url=${url}`,
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // START TRACKING FORM
  const startTrackingCrawledForm = useCallback(async (formId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.post(
        `${HOST_API}/form/track/?cid=zxcvb`,
        {
          formId,
          formName: 'Newsletter',
        },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // ADD FOLLOWER
  const addFollower = useCallback(async (formId: any, userId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `${HOST_API}/form/${formId}/add-follower/?cid=zxcvb`,
        { data: { follower: userId } },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      getFormDetails({ formId });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REMOVE FOLLOWER
  const removeFollower = useCallback(async (formId: any, userId: any) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(
        `${HOST_API}/form/${formId}/remove-follower/?cid=zxcvb`,
        { data: { follower: userId } },
        {
          headers: {
            'Powered-By': 'Produktiv',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      getFormDetails({ formId });
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----

  const memoizedValue = useMemo(
    () => ({
      formContacts: state.formContacts,
      formList: state.formList,
      formDetails: state.formDetails,
      formInactive: state.formInactive,
      loading: state.loading,
      formStatistics: state.formStatistics,
      getFormContacts,
      getFormList,
      getFormDetails,
      getFormStatistics,
      getFormInactive,
      newCrawl,
      startTrackingCrawledForm,
      addFollower,
      removeFollower,
      editFormDetails,
      editFormActive,
      detectScript,
      clearFormDetails,
      changeDate,
      startDate: state.startDate,
      endDate: state.endDate,
      snippetFound: state.snippetFound,
    }),
    [
      state.formContacts,
      state.formList,
      state.formDetails,
      state.loading,
      state.formInactive,
      state.formStatistics,
      getFormContacts,
      getFormList,
      getFormDetails,
      getFormStatistics,
      getFormInactive,
      newCrawl,
      startTrackingCrawledForm,
      addFollower,
      removeFollower,
      editFormDetails,
      editFormActive,
      detectScript,
      clearFormDetails,
      changeDate,
      state.startDate,
      state.endDate,
      state.snippetFound,
    ],
  );

  return (
    <FormsContext.Provider value={memoizedValue}>
      {children}
    </FormsContext.Provider>
  );
}

interface LeadData {
  action: {
    pageUrl: string;
    formId: string;
    formClass: string;
    formData: string;
  };
  firstName: string;
  emailAddress: string;
  phoneNumber: string;
  tracking_id: string;
  utmParams: Record<string, any>;
  extra: Record<string, any>;
  is_active: boolean;
}

interface ApiResponse {
  data: any;
  error?: {
    status: number;
    name: string;
    message: string;
    details: Record<string, any>;
  };
}

export async function postNewLead(data: LeadData, clientId: string): Promise<ApiResponse> {
  try {
    const response = await fetch(`https://dev.api.pro-crm.trakt.pro/api/form-submission?cid=${clientId}`, {
      method: 'POST',
      headers: {
        'Origin': 'produktiv.agency',
        'Powered-By': 'Produktiv',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...data,
        is_active: true
      }),
      redirect: 'follow'
    });

    const result = await response.json();
    return result;

  } catch (error) {
    console.error('Error posting new lead:', error);
    throw error;
  }
} 


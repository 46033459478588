import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { HOST_API } from 'src/config-global';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

// const isProduction = process.env.NODE_ENV === 'production';
// console.log(process.env);
// const API_HOST = isProduction
//     ? process.env.REACT_APP_PROD_HOST_API
//     : process.env.REACT_APP_DEV_HOST_API;

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const res = await axios.get(
          `${HOST_API}/users/me?populate=*`,
          {
            headers: {
              'Powered-By': 'Produktiv',
            },
          },
        );

        const user = res.data.data;

        localStorage.setItem('cid', user.cid || user.account_id.utm);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  //
  // CREATE CONTACT
  const createContact = useCallback(async (dataContact: any) => {
    const res = await axios.post(
      'https://api.trakt.pro/api/leads/?cid=123456&test=qwer',
      { data: dataContact },
      {
        headers: {
          Origin: 'cpp.ca',
          'Powered-By': 'Produktiv',
        },
      },
    );

    const { jwt, user } = res.data;

    setSession(jwt);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
  }, []);

  // TEST
  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const formData = new FormData();

      formData.append('identifier', email);

      formData.append('password', password);

      const res = await axios.post(
        `${HOST_API}/auth/local?cid=zxcvb`,
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Origin: 'produktiv.agency',
            'Powered-By': 'Produktiv',
          },
        },
      );

      const { jwt, user } = res.data;
      localStorage.setItem('cid', user.cid);
      setSession(jwt);
      await initialize();
      // dispatch({
      //   type: Types.LOGIN,
      //   payload: {
      //     user,
      //   },
      // });
      return res.data;
    },
    [initialize],
  );

  const loginGoogle = useCallback(async (search: string) => {
    const res = await axios.get(
      `${HOST_API}/auth/google/callback${search}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );

    const { jwt, user } = res.data;

    setSession(jwt);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user,
      },
    });
    return res.data;
  }, []);

  // REGISTER STEP 1
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
    ) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('username', email);

      const res = await axios.post(
        `${HOST_API}/auth/register-step-1?cid=zxcvb`,
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Origin: 'produktiv.agency',
            'Powered-By': 'Produktiv',
          },
        },
      );

      const { jwt, user } = res.data;

      localStorage.setItem(STORAGE_KEY, jwt);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    [],
  );

  // JOIN LINK
  const joinLink = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      requestId: string,
    ) => {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('username', email);
      formData.append('requestId', requestId);

      const res = await axios.post(
        `${HOST_API}/auth/join-link?cid=zxcvb`,
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Origin: 'produktiv.agency',
            'Powered-By': 'Produktiv',
          },
        },
      );

      const { jwt, user } = res.data;

      localStorage.setItem(STORAGE_KEY, jwt);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    [],
  );

  // JOIN EMAIL
  const joinEmail = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      requestId: string,
    ) => {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('username', email);
      formData.append('requestId', requestId);

      const res = await axios.post(
        `${HOST_API}/auth/join-email?cid=zxcvb`,
        formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Origin: 'produktiv.agency',
            'Powered-By': 'Produktiv',
          },
        },
      );

      const { jwt, user } = res.data;

      localStorage.setItem(STORAGE_KEY, jwt);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    [],
  );

  // REGISTER STEP 2
  const registerStep2 = useCallback(async ({ verificationCode }: any) => {
    const data = {
      verificationCode,
    };
    const accessToken = localStorage.getItem('accessToken');

    await axios.post(
      `${HOST_API}/auth/register-step-2?cid=zxcvb`,
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  }, []);

  // RESEND CODE
  const resendCode = useCallback(async () => {
    const data = {};
    const accessToken = localStorage.getItem('accessToken');

    await axios.post(
      `${HOST_API}/auth/resend-code?cid=zxcvb`,
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: any) => {
    await axios.post(
      `${HOST_API}/auth/forgot-password?cid=zxcvb`,
      { email },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );
  }, []);

  // RESET PASSWORD
  const resetPassword = useCallback(async (data: any, token: any) => {
    await axios.post(
      `${HOST_API}/auth/reset-password?cid=zxcvb`,
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }, []);

  // CHANGE PASSWORD
  const changePassword = useCallback(async (data: any) => {
    await axios.put(
      `${HOST_API}/auth/change-password?cid=zxcvb`,
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );
  }, []);

  // VERIFY EMAIL
  const verifyEmail = useCallback(async (data: any) => {
    await axios.post(
      `${HOST_API}/auth/local/verify-email?cid=zxcvb`,
      data,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Origin: 'produktiv.agency',
          'Powered-By': 'Produktiv',
        },
      },
    );
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      registerStep2,
      logout,
      createContact,
      loginGoogle,
      forgotPassword,
      resetPassword,
      verifyEmail,
      initialize,
      resendCode,
      joinLink,
      joinEmail,
      changePassword,
    }),
    [
      login,
      logout,
      register,
      registerStep2,
      state.user,
      status,
      createContact,
      loginGoogle,
      forgotPassword,
      resetPassword,
      verifyEmail,
      initialize,
      resendCode,
      joinLink,
      joinEmail,
      changePassword,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

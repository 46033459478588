import { lazy } from 'react';
// import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
// import { LoadingScreen } from 'src/components/loading-screen';
import { Outlet } from 'react-router-dom';
// import { UserCreateView, UserListView } from 'src/sections/users/view';

// ----------------------------------------------------------------------

// ACCOUNT
// const Account = lazy(() => import('src/pages/dashboard/account'));
// const DashboardNew = lazy(() => import('src/pages/dashboard-new'));
const DashboardKanbanPage = lazy(() => import('src/pages/dashboard-kanban'));

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));
// const OverviewEcommercePage = lazy(
//   () => import('src/pages/dashboard/ecommerce'),
// );
// const OverviewAnalyticsPage = lazy(
//   () => import('src/pages/dashboard/analytics'),
// );
// const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
// const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
// const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// // PRODUCT
// const ProductDetailsPage = lazy(
//   () => import('src/pages/dashboard/product/details'),
// );
// const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
// const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
// const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// // ORDER
// const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
// const OrderDetailsPage = lazy(
//   () => import('src/pages/dashboard/order/details'),
// );
// // INVOICE
// const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
// const InvoiceDetailsPage = lazy(
//   () => import('src/pages/dashboard/invoice/details'),
// );
// const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
// const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const PersonalProfilePage = lazy(
  () => import('src/pages/dashboard/personal-profile/account'),
);
const CompanyAccountPage = lazy(
  () => import('src/pages/dashboard/company-account/account'),
);
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// // BLOG
// const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
// const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
// const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
// const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(
  () => import('src/pages/dashboard/campaigns/details'),
);
const JobListPage = lazy(() => import('src/pages/dashboard/campaigns/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/campaigns/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/campaigns/edit'));
// CAMPAIGNS
const CampaignDetailsPage = lazy(
  () => import('src/pages/dashboard/campaigns_new/details'),
);
const CampaignListPage = lazy(
  () => import('src/pages/dashboard/campaigns_new/list'),
);
const CampaignCreatePage = lazy(
  () => import('src/pages/dashboard/campaigns_new/new'),
);
const CampaignEditPage = lazy(
  () => import('src/pages/dashboard/campaigns_new/edit'),
);
// TOUR
const FormDetailsPage = lazy(() => import('src/pages/dashboard/form/details'));
const FormListPage = lazy(() => import('src/pages/dashboard/form/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/form/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/form/edit'));
// FILE MANAGER
// const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// // APP
// const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// const MailPage = lazy(() => import('src/pages/dashboard/mail'));
// const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
// const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// // TEST RENDER PAGE BY ROLE
// const PermissionDeniedPage = lazy(
//   () => import('src/pages/dashboard/permission'),
// );
// // BLANK PAGE
// const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          {/* <Suspense fallback={<LoadingScreen />}> */}
            <Outlet />
          {/* </Suspense> */}
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      // { element: <DashboardNew />, index: true },
      { element: <DashboardKanbanPage />, index: true },
      // { element: <div>Dashboard</div>, index: true },
      // { path: 'ecommerce', element: <OverviewEcommercePage /> },
      // { path: 'analytics', element: <OverviewAnalyticsPage /> },
      // { path: 'banking', element: <OverviewBankingPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'personal-profile', element: <PersonalProfilePage /> },
          { path: 'company-account', element: <CompanyAccountPage /> },
        ],
      },
      // {
      //   path: 'product',
      //   children: [
      //     { element: <ProductListPage />, index: true },
      //     { path: 'list', element: <ProductListPage /> },
      //     { path: ':id', element: <ProductDetailsPage /> },
      //     { path: 'new', element: <ProductCreatePage /> },
      //     { path: ':id/edit', element: <ProductEditPage /> },
      //   ],
      // },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':title', element: <BlogPostPage /> },
      //     { path: ':title/edit', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },
      {
        path: 'campaigns_old',
        children: [
          { element: <JobListPage />, index: true },
          // { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          // { path: 'new', element: <JobCreatePage /> },
          // { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'campaigns',
        children: [
          { element: <CampaignListPage />, index: true },
          { path: 'list', element: <CampaignListPage /> },
          { path: ':id', element: <CampaignDetailsPage /> },
          { path: 'new', element: <CampaignDetailsPage /> },
          // { path: 'new', element: <CampaignCreatePage /> },
          { path: ':id/edit', element: <CampaignEditPage /> },
        ],
      },
      {
        path: 'forms',
        children: [
          { element: <FormListPage />, index: true },
          { path: 'list', element: <FormListPage /> },
          { path: ':id', element: <FormDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      // {
      //   path: 'users',
      //   children: [
      //     { element: <UserListView />, index: true },
      //     { path: 'invite', element: <UserCreateView /> },
      //   ],
      // },
      // { path: 'file-manager', element: <FileManagerPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'kanban', element: <KanbanPage /> },
      // { path: 'permission', element: <PermissionDeniedPage /> },
      // { path: 'blank', element: <BlankPage /> },
      // //--
      // { path: 'account', element: <Account /> },
      // { path: 'old-d', element: <IndexPage /> },
    ],
  },
];

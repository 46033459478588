import { useEffect, useCallback, useState } from 'react';
// routes
// import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

// const loginPaths: Record<string, string> = {
//   jwt: paths.auth.jwt.login,
//   auth0: paths.auth.auth0.login,
//   amplify: paths.auth.amplify.login,
//   firebase: paths.auth.firebase.login,
// };

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();

  const { authenticated } = useAuthContext();
  const accessToken = localStorage.getItem('accessToken');

  const [checked, setChecked] = useState(false);
  const check = useCallback(() => {
    if (!authenticated && !accessToken) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      // const loginPath = loginPaths[method];

      const href = `/?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, router, accessToken]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';

// components
import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/auth/guard/auth-guard';
import OnboardingLayout from 'src/layouts/onboarding/layout';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));

// NEW OMBOARDING
const NewOnboardingPage = lazy(
  () => import('src/pages/dashboard/new-onboarding/welcome'),
);
const NewOnboardingInfoPage = lazy(
  () => import('src/pages/dashboard/new-onboarding/info'),
);

// ----------------------------------------------------------------------

export const onboardingRoutes = [
  {
    path: 'onboarding',
    element: (
      <AuthGuard>
        <OnboardingLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </OnboardingLayout>
      </AuthGuard>
    ),
    children: [
      { element: <NewOnboardingInfoPage />, index: true },
      { path: 'welcome', element: <NewOnboardingPage /> },
      { path: 'info', element: <NewOnboardingInfoPage /> },
    ],
  },
];
